import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import LinkIcon from "@mui/icons-material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { fetchPosting, updatePosting } from "../../../actions/postings";

import getSymbolFromCurrency from "currency-symbol-map";
export default function Main(props) {
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.institutions.institutions);
  const history = useHistory();

  function handlePublish() {
    const body = { ...props.data, published: true };
    dispatch(updatePosting(body));
  }

  function handleUnpublish() {
    const body = { ...props.data, published: false };
    dispatch(updatePosting(body));
  }

  function handleOpen() {
    window.open(props.data.link);
  }

  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        borderStyle: "none",
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 1,
        borderColor: "#0093e7",
      }}
    >
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item md={8}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Avatar aria-label="recipe">
                  {" "}
                  <BusinessIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography
                  noWrap
                  variant="body1"
                  color="primary"
                  fontFamily="AgrandirBold"
                >
                  {props.data.title}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  fontFamily={"AgrandirRegular"}
                >
                  {" "}
                  {
                    institutions.filter(
                      (item) => item.code == props.data.institution
                    )[0].name
                  }{" "}
                  | {props.data.province}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ marginTop: 10 }}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Chip
                size="small"
                color="primary"
                style={{ borderRadius: 0 }}
                label={
                  <Typography variant="body2">
                    {" "}
                    {props.data.category}
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <Chip
                size="small"
                style={{ borderRadius: 0 }}
                color="primary"
                label={
                  <Typography variant="body2"> {props.data.type}</Typography>
                }
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
            color="textSecondary"
            align="justify"
          >
            {props.data.description}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Chip
              size="small"
              style={{ borderRadius: 0 }}
              color={props.data.published == true ? "success" : "warning"}
              label={props.data.published == true ? "Published" : "Unpublished"}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <IconButton onClick={() => props.display()} color="primary">
                  <LaunchIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={props.data.published}
                  onClick={() => props.edit()}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton onClick={() => handleOpen()} color="primary">
                  <LinkIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={props.data.published}
                  onClick={() => props.confirm()}
                  color="primary"
                >
                  <DeleteIcon
                    color={props.data.published ? "disabled" : "error"}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
