import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";

import Login from "./web/account/Login";
import Register from "./web/account/Register";
import Auth from "./web/account/Auth";
import SetPassword from "./web/account/SetPassword";
import Dashboard from "./web/dashboard/Main";
import Postings from "./web/postings/Main";
import Institutions from "./web/institutions/Main";
import Institution from "./web/institutions/Institution";

const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export default function Main() {
  return (
    <Default>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/account/login" component={Login} />
        <Route exact path="/account/register" component={Register} />
        <Route exact path="/account/auth" component={Auth} />
        <Route exact path="/account/setpassword" component={SetPassword} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/postings" component={Postings} />
        <Route exact path="/institutions" component={Institutions} />
        <Route exact path="/institutions/:page" component={Institutions} />
      </Switch>
    </Default>
  );
}
