import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import GridItem from "../../components/web/postings/PostingItem";

import { blue, red } from "@mui/material/colors";
import {
  getPostings,
  fetchPosting,
  deletePosting,
} from "../../actions/postings";

import AddModal from "../../components/web/postings/AddPosting";
import EditModal from "../../components/web/postings/EditPosting";
import WizardModal from "../../components/web/postings/Wizard";
import ConfirmModal from "../../components/web/modals/Confirmation";
import DisplayModal from "../../components/web/postings/DisplayPosting";

export default function Main(props) {
  const history = useHistory();
  const [display, setDisplay] = useState(0);
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [wizard, setWizard] = useState(false);

  const postings = useSelector((state) => state.postings.postings);
  const posting = useSelector((state) => state.postings.posting);
  const [data, setData] = useState(postings ? postings : []);
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    start: 0,
    end: 8,
    page: 1,
    pages: Math.ceil(data.length / 9),
  });

  useEffect(() => {
    setData(postings ? postings : []);
  }, [postings]);

  useEffect(() => {
    setData(postings ? postings : []);
  }, []);

  function handlePageChange(event, value) {
    setPagination((pagination) => ({
      ...pagination,
      start: (value - 1) * 9,
      end: value * 9 - 1,
      page: value,
    }));
  }

  function handleFilter(filter) {
    let filteredData = postings;

    // Filter by location unless "all"
    if (filter.province.toLowerCase() !== "all") {
      filteredData = filteredData.filter(
        (item) => item.province === filter.province
      );
    }

    // Filter by searchText (case-insensitive)
    if (filter.search) {
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(filter.search.toLowerCase())
      );
    }

    //Filter by status
    if (filter.status === "published") {
      filteredData = filteredData.filter((item) => item.published === true);
    } else if (filter.status === "draft") {
      filteredData = filteredData.filter((item) => item.published === false);
    }
    // Update the state
    setData(filteredData);
  }

  function handleEdit(item) {
    setEdit(true);
    dispatch(fetchPosting(item));
  }

  function handleConfirm(item) {
    setConfirm(true);
    dispatch(fetchPosting(item));
  }

  function handleDisplay(item) {
    setDisplay(true);
    dispatch(fetchPosting(item));
  }

  function handleClick() {
    setModal(true);
  }

  function handleDelete() {
    setConfirm(false);
    dispatch(deletePosting(posting));
  }

  return (
    <div>
      <AddModal open={modal} close={() => setModal(false)} />
      <EditModal open={edit} close={() => setEdit(false)} />
      <DisplayModal open={display} close={() => setDisplay(false)} />
      <WizardModal open={wizard} close={() => setWizard(false)} />
      <ConfirmModal
        open={confirm}
        close={() => setConfirm(false)}
        confirm={() => handleDelete()}
      />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontFamily: "SpaceBold", color: "#0093e7" }}
          >
            Postings
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Create and manage postings
          </Typography>
        </Grid>

        <Grid item>
          <Button onClick={handleClick} startIcon={<AddIcon />}>
            <Typography variant="body1" style={{ fontFamily: "NeueBold" }}>
              Add Posting
            </Typography>
          </Button>{" "}
        </Grid>
      </Grid>

      <div style={{ marginTop: 30 }}>
        <Grid container direction="row">
          <Grid item md={12} lg={8}>
            <Toolbar update={(filter) => handleFilter(filter)} />
            <div style={{ marginTop: 20 }} />

            {data.map(
              (item, index) =>
                pagination.start <= index &&
                pagination.end >= index && (
                  <>
                    <GridItem
                      data={item}
                      edit={() => handleEdit(item)}
                      display={() => handleDisplay(item)}
                      confirm={() => handleConfirm(item)}
                    />
                    <Divider />
                  </>
                )
            )}

            <div style={{ marginTop: 20 }} />
            {data.length > 8 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={pagination.pages}
                  page={pagination.page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Toolbar(props) {
  const [form, setForm] = useState({
    search: "",
    province: "all",
    status: "all",
  });

  useEffect(() => {
    props.update(form);
  }, [form]);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item md={6}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
      <Grid item md={4}>
        <TextField
          required
          fullWidth
          id="province"
          name="province"
          label="Province"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="standard"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"AB"}> Alberta </MenuItem>
          <MenuItem value={"BC"}> British Columbia </MenuItem>
          <MenuItem value={"MB"}> Manitoba </MenuItem>
          <MenuItem value={"NB"}> New Brunswick </MenuItem>
          <MenuItem value={"NL"}> Newfoundland and Labrador </MenuItem>
          <MenuItem value={"NT"}> Northwest Territories </MenuItem>
          <MenuItem value={"NS"}> Nova Scotia </MenuItem>
          <MenuItem value={"NU"}> Nuvanut </MenuItem>
          <MenuItem value={"ON"}> Ontario </MenuItem>
          <MenuItem value={"PE"}> Prince Edward Island </MenuItem>
          <MenuItem value={"QC"}> Quebec </MenuItem>
          <MenuItem value={"SK"}> Saskatechewan </MenuItem>
          <MenuItem value={"YT"}> Yukon </MenuItem>
        </TextField>
      </Grid>
      <Grid item md={2}>
        <TextField
          required
          fullWidth
          id="status"
          name="status"
          label="Status"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="standard"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"draft"}> Draft </MenuItem>
          <MenuItem value={"published"}> Published </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
