import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import LaunchIcon from "@mui/icons-material/Launch";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ConfirmModal from "../modals/Confirmation";
import {
  fetchInstitution,
  deleteInstitution,
} from "../../../actions/institutions";

export default function Main(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setModal] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleOpen(data) {
    dispatch(fetchInstitution(data));
    history.push("/institutions/institution");
  }

  function handleDelete() {
    const body = { id: props.data.id };
    dispatch(deleteInstitution(body));
  }

  return (
    <>
      <ConfirmModal
        open={confirm}
        close={() => setConfirm(false)}
        confirm={() => handleDelete(props.data)}
      />
      <ListItem divider>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item md={2}>
            <Avatar />
          </Grid>
          <Grid item md={6}>
            <Typography
              style={{ fontFamily: "OpenSauceRegular" }}
              variant="body1"
              noWrap
            >
              {" "}
              {props.data.name}{" "}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{ fontFamily: "OpenSauceRegular" }}
              variant="body1"
              noWrap
            >
              {" "}
              {props.data.code}{" "}
            </Typography>
          </Grid>

          <Grid item md={1}>
            <Typography
              style={{ fontFamily: "OpenSauceRegular" }}
              variant="body1"
              noWrap
            >
              {" "}
              {props.data.province}{" "}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={() => handleOpen(props.data)} color="primary">
              <LaunchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}
