import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import NewPosting from "./NewPosting";

export default function Main(props) {
  const [open, setOpen] = useState(props.open);
  const posting = useSelector((state) => state.postings.posting);
  const institutions = useSelector((state) => state.institutions.institutions);
  const dispatch = useDispatch();

  function handleClose() {
    props.close();
  }

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar elevation="0" color="inherit" sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{ ml: 2, flex: 1 }}
            fontFamily={"SpaceBold"}
            variant="h6"
            color="primary"
            component="div"
          >
            {posting.title}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent style={{ justifyContent: "center" }}>
        <Grid container direction="row">
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary">
              Institution
            </Typography>
            <Typography variant="body1">
              {institutions.filter((item) => item.code === posting.institution)
                .length > 0 &&
                institutions.filter(
                  (item) => item.code === posting.institution
                )[0].name}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary">
              Province
            </Typography>
            <Typography variant="body1">{posting.province}</Typography>
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }} />
        <Grid container direction="row">
          <Grid item md={12}>
            <Typography variant="body2" color="textSecondary">
              Description
            </Typography>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              variant="body1"
            >
              {posting.description}
            </Typography>
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }} />
        <Grid container direction="row">
          <Grid item md={4}>
            <Typography variant="body2" color="textSecondary">
              Internal ID
            </Typography>
            <Typography variant="body1">{posting.internal_id}</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" color="textSecondary">
              Category
            </Typography>
            <Typography variant="body1">{posting.category}</Typography>
          </Grid>

          <Grid item md={4}>
            <Typography variant="body2" color="textSecondary">
              Job Type
            </Typography>
            <Typography variant="body1">{posting.type}</Typography>
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }} />

        <Grid container direction="row">
          <Grid item md={12}>
            <Typography variant="body2" color="textSecondary">
              URL
            </Typography>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              variant="body1"
            >
              {posting.link}
            </Typography>
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }} />
        <Grid container direction="row">
          <Grid item md={3}>
            <Typography variant="body2" color="textSecondary">
              Salary
            </Typography>
            <Typography variant="body1">{posting.salary}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body2" color="textSecondary">
              Remote
            </Typography>
            <Typography variant="body1">{posting.remote}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body2" color="textSecondary">
              Posting Date
            </Typography>
            <Typography variant="body1">{posting.posting_date}</Typography>
          </Grid>

          <Grid item md={3}>
            <Typography variant="body2" color="textSecondary">
              Closing Date
            </Typography>
            <Typography variant="body1">{posting.closing_date}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
