export function addPosting(form, step) {
  if (step === 0) {
    return basic(form);
  } else if (step === 1) {
    return details(form);
  }
}

function basic(form) {
  var status = "error";
  var message = "";
  if (form.title === "" || form.title == null) {
    message = "Please provide the title of the posting";
  } else if (form.description === "" || form.description == null) {
    message = "Please provide a description of the position";
  } else if (form.link === "" || form.link == null) {
    message = "Please provide the posting link";
  } else if (form.type === "" || form.type == null) {
    message = "Please specify the posting type";
  } else if (form.institution === "" || form.institution == null) {
    message = "Please specify the institution";
  } else if (form.category === "" || form.category == null) {
    message = "Please specify the posting category";
  } else {
    status = "success";
  }

  return [status, message];
}

function details(form) {
  var status = "error";
  var message = "";
  if (form.title === "" || form.title == null) {
    message = "Please provide the title of the posting";
  } else if (form.description === "" || form.description == null) {
    message = "Please provide a description of the position";
  } else if (form.link === "" || form.link == null) {
    message = "Please provide the posting link";
  } else if (form.type === "" || form.type == null) {
    message = "Please specify the posting type";
  } else if (form.institution === "" || form.institution == null) {
    message = "Please specify the institution";
  } else if (form.category === "" || form.category == null) {
    message = "Please specify the posting category";
  } else {
    status = "success";
  }

  return [status, message];
}
