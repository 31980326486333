import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import AddIcon from "@mui/icons-material/Add";

import { blue, red } from "@mui/material/colors";
import { getInstitutions } from "../../actions/institutions";

import DataItem from "../../components/web/institutions/DataItem";
import WizardModal from "../../components/web/postings/Wizard";

export default function Main(props) {
  const history = useHistory();
  const [display, setDisplay] = useState(0);
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [wizard, setWizard] = useState(false);
  const institutions = useSelector((state) => state.institutions.institutions);
  const [data, setData] = useState(institutions ? institutions : []);
  const [pagination, setPagination] = useState({
    start: 0,
    end: 8,
    page: 1,
    pages: Math.ceil(data.length / 10),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstitutions());
  }, []);

  useEffect(() => {
    setPagination((pagination) => ({
      ...pagination,
      pages: Math.ceil(data.length / 10),
    }));
  }, [data]);

  function handlePageChange(event, value) {
    setPagination((pagination) => ({
      ...pagination,
      start: (value - 1) * 9,
      end: value * 9 - 1,
      page: value,
    }));
  }

  useEffect(() => {
    setData(institutions ? institutions : []);
  }, [institutions]);

  function handleFilter(searchText, location) {
    if (location === "all") {
      setData(
        institutions
          .filter((item) => item.province !== location)
          .filter((item) => item.name.includes(searchText))
      );
    } else {
      setData(
        institutions
          .filter((item) => item.province === location)
          .filter((item) => item.name.includes(searchText))
      );
    }
  }

  function handleClick() {
    setModal(true);
  }

  return (
    <div>
      <WizardModal open={wizard} close={() => setWizard(false)} />

      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontFamily: "NeueBold", color: "#0093e7" }}
          >
            Institutions
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Create and manage institutions
          </Typography>
        </Grid>
      </Grid>

      <div style={{ marginTop: 30 }}>
        <Grid container direction="row">
          <Grid item md={12} lg={8}>
            <Toolbar
              update={(search, location) => handleFilter(search, location)}
            />
            <div style={{ marginTop: 20 }} />
            <Grid container direction="row" spacing={1}>
              {data.length > 0 ? (
                data.map(
                  (item, index) =>
                    index >= pagination.start &&
                    index <= pagination.end && <DataItem data={item} />
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Typography
                    variant="h6"
                    justify="center"
                    style={{ fontFamily: "OnestBold" }}
                    color="textSecondary"
                  >
                    {" "}
                    No data to display
                  </Typography>
                </div>
              )}
            </Grid>

            <div style={{ marginTop: 20 }} />
            {data.length > 8 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={pagination.pages}
                  page={pagination.page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Toolbar(props) {
  const [form, setForm] = useState({ search: "", location: "all" });

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "search") {
      props.update(value, form.location);
    } else {
      props.update(form.search, value);
    }
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <Grid container direction="row" spacing={1} alignItems={"center"}>
      <Grid item md={6}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          required
          fullWidth
          id="province"
          name="province"
          label="Province"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="standard"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"AB"}> Alberta </MenuItem>
          <MenuItem value={"BC"}> British Columbia </MenuItem>
          <MenuItem value={"MB"}> Manitoba </MenuItem>
          <MenuItem value={"NB"}> New Brunswick </MenuItem>
          <MenuItem value={"NL"}> Newfoundland and Labrador </MenuItem>
          <MenuItem value={"NT"}> Northwest Territories </MenuItem>
          <MenuItem value={"NS"}> Nova Scotia </MenuItem>
          <MenuItem value={"NU"}> Nuvanut </MenuItem>
          <MenuItem value={"ON"}> Ontario </MenuItem>
          <MenuItem value={"PE"}> Prince Edward Island </MenuItem>
          <MenuItem value={"QC"}> Quebec </MenuItem>
          <MenuItem value={"SK"}> Saskatechewan </MenuItem>
          <MenuItem value={"YT"}> Yukon </MenuItem>
        </TextField>
      </Grid>
      <Grid item md={3}>
        <TextField
          required
          fullWidth
          id="type"
          name="type"
          label="Type"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="standard"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"college"}> College </MenuItem>
          <MenuItem value={"university"}> University </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
