import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import getSymbolFromCurrency from "currency-symbol-map";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";

import { uri } from "../../../constants/settings";
import { wait, notify, unexpectedError } from "../../../actions/index";
import { updatePosting } from "../../../actions/postings";
import * as validator from "../../../validators/postings";

const base64 = require("base-64");

const steps = ["Basic", "Details", "Preview"];

const styles = {
  input: {
    display: "none",
  },
};

export default function Main(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const posting = useSelector((state) => state.postings.posting);
  const [form, setForm] = useState(posting);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  const handleNext = () => {
    if (activeStep < 2) {
      const [status, message] = validator.addPosting(form, activeStep);
      if (status == "success") {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        dispatch(notify({ status: status, message: message }));
      }
    } else if (activeStep == steps.length - 1) {
      dispatch(updatePosting(form, props));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} style={{ width: "100%" }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    <Typography variant="body1">{label}</Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <>
              <div style={{ marginTop: 50 }} />
              {activeStep == 0 && (
                <AboutForm form={form} handleChange={handleChange} />
              )}
              {activeStep == 1 && (
                <DetailsForm form={form} handleChange={handleChange} />
              )}
              {activeStep == 2 && <Preview form={form} />}

              <div style={{ marginTop: 50 }} />
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                <Typography variant="body1" fontFamily={"SpaceBold"}>
                  Back
                </Typography>
              </Button>

              <Button onClick={handleNext}>
                <Typography variant="body1" fontFamily="SpaceBold">
                  {" "}
                  {activeStep === steps.length - 1 ? "Update" : "Next"}
                </Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function AboutForm(props) {
  const [form, setForm] = useState(props.form);
  const institutions = useSelector((state) => state.institutions.institutions);

  function handleChange(event) {
    props.handleChange(event);
  }

  useEffect(() => {
    console.log(props.form);
  }, [props.form]);
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="Internal ID"
              name="internal_id"
              required
              variant="standard"
              onChange={handleChange}
              defaultValue={form.internal_id}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Category"
              name="category"
              required
              select
              variant="standard"
              onChange={handleChange}
              defaultValue={form.category}
              size="small"
              fullWidth
            >
              <MenuItem value="academic"> Academic </MenuItem>
              <MenuItem value="adminstrative"> Administrative </MenuItem>
              <MenuItem value="support"> Support </MenuItem>
              <MenuItem value="technical"> Technical </MenuItem>
              <MenuItem value="other"> Other </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Type"
              name="type"
              select
              required
              variant="standard"
              onChange={handleChange}
              defaultValue={form.type}
              size="small"
              fullWidth
            >
              <MenuItem value="full-time"> Full-time </MenuItem>
              <MenuItem value="part-time"> Part-time </MenuItem>
              <MenuItem value="contract"> Contract </MenuItem>
              <MenuItem value="other"> Other </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }}>
        <TextField
          name="title"
          required
          variant="standard"
          defaultValue={form.title}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Title"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="link"
          required
          variant="standard"
          defaultValue={form.link}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Link"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item md={4}>
            <TextField
              label="Province"
              name="province"
              required
              select
              variant="standard"
              onChange={handleChange}
              defaultValue={form.province}
              size="small"
              fullWidth
            >
              <MenuItem value={"AB"}> Alberta </MenuItem>
              <MenuItem value={"BC"}> British Columbia </MenuItem>
              <MenuItem value={"MB"}> Manitoba </MenuItem>
              <MenuItem value={"NB"}> New Brunswick </MenuItem>
              <MenuItem value={"NL"}> Newfoundland and Labrador </MenuItem>
              <MenuItem value={"NT"}> Northwest Territories </MenuItem>
              <MenuItem value={"NS"}> Nova Scotia </MenuItem>
              <MenuItem value={"NU"}> Nuvanut </MenuItem>
              <MenuItem value={"ON"}> Ontario </MenuItem>
              <MenuItem value={"PE"}> Prince Edward Island </MenuItem>
              <MenuItem value={"QC"}> Quebec </MenuItem>
              <MenuItem value={"SK"}> Saskatechewan </MenuItem>
              <MenuItem value={"YT"}> Yukon </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={8}>
            <TextField
              name="institution"
              required
              variant="standard"
              defaultValue={form.address}
              onChange={handleChange}
              fullWidth
              select
              size="small"
              label="Institution"
            >
              {institutions.map(
                (item) =>
                  props.form.province == item.province && (
                    <MenuItem value={item.code}>{item.name}</MenuItem>
                  )
              )}
            </TextField>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="description"
          required
          variant="standard"
          defaultValue={form.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          size="small"
          label="Description"
        />
      </div>
    </>
  );
}

function DetailsForm(props) {
  const [form, setForm] = useState(props.form);

  function handleChange(event) {
    props.handleChange(event);
  }
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Qualifications"
              name="qualification"
              size="small"
              select
              variant="standard"
              onChange={handleChange}
              defaultValue={form.qualification}
              fullWidth
            >
              <MenuItem value="high-school">High School</MenuItem>
              <MenuItem value="bachelor">Bachelor</MenuItem>
              <MenuItem value="master">Master</MenuItem>
              <MenuItem value="doctoral">Doctoral</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="unspecified">Not Specified</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Experience (In Years)"
              name="experience"
              type="text"
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.experience}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Remote"
              name="remote"
              required
              select
              type="number"
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.remote}
              fullWidth
            >
              <MenuItem value="yes"> Yes </MenuItem>
              <MenuItem value="no"> No </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Salary"
              name="salary"
              type="text"
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.salary}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Skills"
              name="skills"
              required
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.skills}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Posting Date"
              name="posting_date"
              type="date"
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.posting_date}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Closing Date"
              name="closing_date"
              type="date"
              size="small"
              variant="standard"
              onChange={handleChange}
              defaultValue={form.closing_date}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function Preview(props) {
  const posting = props.form;
  const institutions = useSelector((state) => state.institutions.institutions);

  return (
    <div>
      <div style={{ marginTop: 20 }} />
      <ListItem
        component="div"
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography style={{ fontFamily: "SpaceBold" }} variant="h6" noWrap>
              {" "}
              {posting.title}{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" fontFamily="SpaceBold">
              {getSymbolFromCurrency("CAD")}
              {posting.salary}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Grid item md={4}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <CorporateFareIcon style={{ color: "#808080" }} />
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body1" noWrap>
                  {
                    institutions.filter(
                      (item) => item.code === posting.institution
                    )[0].name
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <LocationOnIcon style={{ color: "#808080" }} />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {posting.remote}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <SchoolIcon style={{ color: "#808080" }} />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {posting.qualification}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }}>
          <Typography variant="body1" color="textSecondary">
            {posting.description}
          </Typography>
        </div>
      </ListItem>
    </div>
  );
}
